import React, { useState, useEffect, useRef } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import Logo from "../logo/Logo";
// import resume from "../../static/resume.pdf"
import { useScrollDirection } from "../../hooks/useScrollDirection";
import useOutsideClick from "../../hooks/useOutsideClick";
import "./header.css"
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BsGithub } from "react-icons/bs";
// import {AiFillLinkedin} from "react-icons/ai";

function Header() {
  const [toggle, setToggle] = useState(false);
  const scrollDirection = useScrollDirection();
  const sidebarRef = useRef(null);
  const outSidebarClick = useOutsideClick(sidebarRef);

  //toggling the sidebar menu if click outside of it.
  useEffect(() => {
    if(outSidebarClick){
      setToggle(false)
    }
  },[outSidebarClick])

  const icon = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: "rgba(255, 255, 255, 0)",
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      fill: "rgba(255, 255, 255, 1)",
    },
  };

  return (
    <nav ref ={sidebarRef} 
    className={`ease-in-out duration-150 sticky ${ scrollDirection === "down" ? "-top-24" : "top-0"} z-50 bg-[#0b1729]  h-[98px] sm:h-[102px] flex items-center justify-between w-full shadow-2xl`}>
      <div className="flex items-center justify-between px-8">
        <motion.div
          variants={icon}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 1.5, ease: "easeInOut" },
            fill: { duration: 2, ease: [1, 0, 0.8, 1] },
          }}
        >
          <div className="text-[#808040] text-lg md:text-xl lg:text-xl font-medium cursor-pointer">
            <Logo className="cursor-pointer" />
          </div>
        </motion.div>
      </div>

      <div className="">
        <ul className="hidden sm:flex items-center justify-center text-[#808040] space-x-5 md: text-base lg:text-lg 2xl:text-xl  px-12 ">
          {["Home", "Skills", "Projects", "Contact"].map((item) => (
            <li
              className="px-1 font-comorants font-bold hoverText"
              key={`link-${item}`}
            >
              <div className=" hover:border" />
              <div className="flex items-center jsutify-center">
                <a href={`#${item.toLowerCase()}`}>{item}</a>
              </div>
            </li>
          ))}
          <li className=" cursor-pointer flex items-center justify-center border border-[#eee344] hover:border-[#64ffda] hover:bg-[#06241d] w-24 h-10 rounded-sm  ease-in-out duration-300  ">
            {/* <a href={resume} target="_blank" rel="noreferrer" className=" text-[#64ffda] hoverText font-bold font-comorants  "> */}
            <a href="/static/media/resume.d74279002fdd039b3afc.pdf" target="_blank" rel="noreferrer" className=" text-[#64ffda] hoverText font-bold font-comorants  ">
              Resume
            </a>
          </li>
        </ul>


        {/* SIDEBAR */}
        <div className="sm:hidden h-[40px] relative flex items-center justify-center ]">
          {!toggle && (
            <HiMenuAlt4
              className=" text-[#64ffda] hover:text-[#519f8d] cursor-pointer mr-8 h-8 w-8"
              onClick={() => setToggle(!toggle)}
            />
          )}

          <motion.div
            d="M0 100V0l50 50 50-50v100L75 75l-25 25-25-25z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 5, ease: [1, 0, 0.8, 1] },
            }}
            className={`fixed top-0 right-0 z-50 h-screen w-[50vw] 
            flex flex-col justify-end items-end 
            bg-[#001F3F] ${
              toggle ? "translate-x-0" : "translate-x-full"
            } ease-out duration-700  shadow-2xl`}
          >
            
            <ul className="h-[100%] w-full flex flex-col justify-start items-center mt-5 space-y-32">
              <motion.div
                className="ml-auto"
                initial={{ scale: 0 }}
                animate={{ rotate: 180, scale: 1.3 }}
                transition={{
                  type: "spring",
                  stiffness: 150,
                  damping: 5,
                }}
              >
                <HiX
                  className="hoverText m-3 w-[20px] h-[20px] "
                  onClick={() => setToggle(false)}
                />
              </motion.div>

              <div className="space-y-2">
                {["Home", "Skills","Projects", "Contact"].map((item) => (
                  <li
                  key={item}
                  className="hoverText w-[100%] flex items-start justify-start text-[#808040] text-base font-comorants"
                  >
                    <a href={`#${item.toLowerCase()}`} onClick={() => setToggle(false)}>
                      {item}
                    </a>
                  </li>
                ))}
                  <li 
                  className="flex items-start justify-start rounded-sm  ease-in-out duration-300">
                    {/* <a href={resume} target="_blank" rel="noreferrer" className=" text-[#64ffda] hover:hoverText font-bold font-comorants "> */}
                    <a href="/static/media/resume.d74279002fdd039b3afc.pdf" target="_blank" rel="noreferrer" className=" text-[#64ffda] hover:hoverText font-bold font-comorants ">
                      Resume
                    </a>
                  </li>
              </div>

              <div className="contact-links pt-10 md:pt-0 flex flex-row md:flex-col space-x-2 md:space-x-0 md:space-y-5 md:ml-auto justify-center">
                <a
                  href="https://github.com/osefatul"
                  className="contact "
                  target={"blank"}>
                  <BsGithub className="contactIcon" />
                </a>

                <a
                  href="https://wa.me/17789577596"
                  className="contact whatsapp"
                  target={"blank"}>
                  <AiOutlineWhatsApp className="contactIcon" />
                </a>

                {/* <a href="https://www.linkedin.com/in/sefatullah-omar-83b3b0123/" className="contact">
                  <AiFillLinkedin className="contactIcon" />
                </a> */}
              </div>
            </ul>
          </motion.div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
