import React, { useEffect } from 'react'
import Feed from '../feed/Feed'
import Projects from '../mainProjects/Projects';
import OtherProjects from '../otherProjects/OtherProjects';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Qualifications from '../qualifications/qualifications';
import Contact from '../contact/contact';


// 
function Main() {
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className='flex flex-col items-center justify-center mx-auto w-[100%] md:w-[90%] overflow-x-hidden overflow-y-hidden'>
      <div
      className='w-full'
      data-aos="fade-up"
      data-aos-delay="40"
      data-aos-duration="350"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true">
      <Feed/>
      </div>

      <div
      className='w-full'
      data-aos="fade-right"
      data-aos-delay="40"
      data-aos-duration="450"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true">
      <Qualifications/>
      </div>
            
      <div
      className='w-full'
      data-aos="fade-right"
      data-aos-delay="40"
      data-aos-duration="450"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
      >
      <Projects/>
      </div>

      <div
      className='w-full'
      data-aos="fade-right"
      data-aos-delay="40"
      data-aos-duration="350"
      data-aos-easing="ease-in-out"
      // data-aos-mirror="true"
      data-aos-once="true"
      >
      <OtherProjects/>
      </div>


      <div
      className='w-full'
      data-aos="fade-right"
      data-aos-delay="40"
      data-aos-duration="350"
      data-aos-easing="ease-in-out"
      // data-aos-mirror="true"
      data-aos-once="true"
      >
      <Contact/>
      </div>

      <div>
        <div id="footer" className="flex flex-col mx-auto ml-10 md:flex-row text-[12px] md:text-md justify-center items-center text-[#CCD6F6] h-44 -mt-10 space-x-2">
          <span className=" italic">
          “If you think good architecture is expensive, try bad architecture.”
          </span>
          <span>
          — Brian Foote and Joseph Yoder  
          </span>
          
        </div>
      </div>
    </div>
  )
  }

export default Main