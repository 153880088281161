/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./RightNavigator.css";
import { BsMouse } from "react-icons/bs";
import { BsGithub } from "react-icons/bs";
// import {AiFillLinkedin} from "react-icons/ai";

function RightNavbar() {
  return (
    <div className="scroll-down mt-20">
      <a href="https://github.com/osefatul">
        <BsGithub style={{fontSize:"40px"}} className="icon text-[32px]" />
      </a>
      
      {/* <a href="https://www.linkedin.com/in/sefatullah-omar-83b3b0123/">
        <AiFillLinkedin style={{fontSize:"40px"}} className="icon text-[32px]" />
      </a> */}

      <a href="#" className=" text-white ">
        <BsMouse style={{fontSize:"40px"}} className="icon text-[32px]" />
      </a>
      <hr className="bar bg-[#a8b2d1] w-[1px] h-56 mt-" />
    </div>
  );
}

export default RightNavbar;
