/* eslint-disable jsx-a11y/anchor-is-valid */
import "./LeftNavigator.css";
import { AiOutlineHome, AiFillAppstore } from "react-icons/ai";
import { BiMessageRoundedDots } from "react-icons/bi";
import { BsArrowDownCircle } from "react-icons/bs";
import {ImStack} from "react-icons/im"
import {FaLaptopCode} from "react-icons/fa";


function Navbar() {
  return (
    <div className="navigation">
      <a href="#">
        <AiOutlineHome className="icon " />
      </a>
      <a href="#skills">
        <FaLaptopCode className="icon" />
      </a>
      <a href="#projects">
        <ImStack className="icon" />
      </a>
      <a href="#moreProjects">
        <AiFillAppstore className="icon" />
      </a>
      <a href="#contact">
        <BiMessageRoundedDots className="icon" />
      </a>
      <a href="#footer">
        <BsArrowDownCircle className="icon" />
      </a>
      {/* <hr className="bar sm:flex bg-[#a8b2d1] w-[1px] h-56 mt-2 " /> */}
      <hr className="flex bg-[#a8b2d1] w-[1px] h-56 mt-2 " />

    </div>
  );
}

export default Navbar;
