import React from "react";
import "./qualifications.css";
import angular from "../props/angular.png"
import antd from "../props/antd.svg";
import apollo from "../props/apollo.png"
import apolloServer from "../props/apolloserver.png"
import axios from "../props/axios.png"
import boot from "../props/boot.png"
import docker from "../props/docker.png"
import express from "../props/express.png"
import esbuild from "../props/esbuild.svg"
import formik from "../props/formik.png"
import git from "../props/git.png"
import github from "../props/github.png"
import graphql from "../props/graphql.png"

import jwt from "../props/jwt-3.svg"
import jest from "../props/jest.png"

import redux from "../props/redux.png"
import react from "../props/react.png"
import rxjs from "../props/rxjs-1.svg"

import hook from "../props/hook.png"
import recoil from "../props/Recoil.png"

import mongoose from "../props/mongoose.webp"
import mongodb from "../props/mongodb.png"
import mu5 from "../props/mu5.png"
import node from "../props/node.png"
import next from "../props/next.png"
import nest from "../props/Nest.svg"
import webpack from "../props/webpack.svg"
import query from "../props/query.svg"

import pg from "../props/pg.png"
import redis from "../props/redis.png"
import sass from "../props/sass.png"
import socketIo from "../props/socket.png"
import sqlite from "../props/sqLite3.png"
import tailwindcss from "../props/tailwindcss.svg"

import amazonCloudFront from "../props/Amazon-CloudFront.png"
import awsRoute from "../props/aws_route53.png"
import awsDynamoDB from "../props/aws-dynamodb.webp"
import awsEbs from "../props/aws-ebs.webp"
import awsEc2 from "../props/aws-ec2.webp"
import awsElasticBeanStalk from "../props/aws-elastic-beanstalk.webp"
import awsElasticCache from "../props/aws-elasticache.webp"
import awsIam from "../props/aws-iam.png"
import awsLambda from "../props/aws-lambda.webp"
import awsRds from "../props/aws-rds.webp"
import awsS3 from "../props/aws-s3.webp"
import vue from "../props/vue.png"



const Qualifications = () => {
  return (
    <div
    id="skills"
    className=" text-white mx-auto flex flex-col md:items-center justify-start 2xl:justify-center relative w-[100%] sm:w-[90%] md:w-[80%] py-10">
        <div className='flex flex-col items-start justify-start w-[100%]'>
            <p className="text-[#64ffda] text-[8px] sm:text-[10px] lg:text-[14px] xl:text-[16x]">
                My Skills
            </p>
            <div className='flex items-center justify-start space-x-5 w-[100%]'>
                <p className='text-[#CCD6F6] font-bold text-[26px] sm:text-[35px]  '>
                  Technologies
                </p>
                <hr className="none sm:flex lg:w-[30%] h-[2px] text-black bg-slate-800 opacity-30" />
            </div>
        </div>


        <div class="flex flex-col w-full justify-center items-center mx-auto pt-5">
          <div className="flex flex-col w-[80%]">
            <h1 className="text-[#CCD6F6] text-left text-[15px] sm:text-[22px] font-bold border-b-2 max-w-max border-yellow-500">Frontend</h1>
            <div className="icons items-center grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-4 py-3">
              <span className="w-10 items-center flex flex-col justify-center ">
                <img src="../props/js.png" alt="" />
                <span className="text-[12px]">Javascript</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center  ">
                <img src="../props/typescript.png" alt="" />
                <span className="text-[12px]">Typescript</span>
              </span>
              
              <span className=" w-10 items-center flex flex-col justify-center ">
                <img className="rounded-full" src={react} alt=""/>
                <span className="text-[12px]">React</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
                <img className="rounded-full" src={next} alt=""/>
                <span className="text-[12px]">Next.js</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
                <img className="rounded-full" src={vue} alt=""/>
                <span className="text-[12px]">Vue.js</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
                <img className="" src={angular} alt=""/>
                <span className="text-[12px]">Angular</span>
              </span>
              

              <span className=" w-10 items-center flex flex-col justify-center ">
                <img className="rounded-full" src={rxjs} alt=""/>
                <span className="text-[12px]">RxJs</span>
              </span>


              <span className=" w-10 items-center flex flex-col justify-center ">
                <img className="rounded-full" src={redux} alt=""/>
                <span className="text-[12px]">Redux</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
                <img className="rounded-full " src={query} alt=""/>
                <span className="pl-1 text-[12px]">React Query</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
                <img className="rounded-full" src={sass} alt=""/>
                <span className="text-[12px]">Sass</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
                <img className="rounded-full" src={webpack} alt=""/>
                <span className="text-[12px]">Webpack</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
                <img className="" src={axios} alt=""/>
                <span className="text-[12px]">Axios</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
                <img className="" src={recoil} alt=""/>
                <span className="text-[12px]">Recoil</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
              <img className="" src={apollo} alt=""/>
              <span className="text-[12px]">Apollo</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
              <img className="" src={boot} alt=""/>
              <span className="text-[12px]">Bootstrap</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
                <img className="rounded-full" src={jest} alt=""/>
                <span className="text-[12px]">Jest</span>
              </span>

              
              <span className=" w-10 items-center flex flex-col justify-center ">
              <img className="" src={tailwindcss} alt=""/>
              <span className="text-[12px]">Tailwindcss</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
              <img className="" src={antd} alt=""/>
              <span className="text-[12px]">Antd</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
              <img className="" src={esbuild} alt=""/>
              <span className="text-[12px]">Esbuild</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
              <img className="" src={hook} alt=""/>
              <span className="text-[12px] pl-1">React Hook</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
              <img className="" src={mu5} alt=""/>
              <span className="text-[12px]">MUI</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
              <img className="" src={graphql} alt=""/>
              <span className="text-[12px]">GraphQL</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center ">
              <img className="" src={formik} alt=""/>
              <span className="text-[12px]">Formik</span>
              </span>
            </div>
          </div>

          <div className="flex flex-col w-[80%] pt-8">
            <h1 className="text-[#CCD6F6] text-[15px] sm:text-[22px] font-bold border-b-2 max-w-max border-yellow-500">Backend</h1>
            <div className="icons grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-4 py-3 ">
              <span className=" w-10 items-center flex flex-col justify-center  ">
                <img className="h-10"  src={node} alt="" />
                <span className="text-[12px]">Node.js</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center  ">
                <img  className="rounded-full"  src={express} alt="" />
                <span className="text-[12px]">Express.js</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center  ">
                <img src={nest} alt="" />
                <span className="text-[12px]">Nest.js</span>
              </span>

              <span className="w-10 items-center flex flex-col justify-center  ">
                <img className="rounded-full h-10"  src={mongoose} alt="" />
                <span className="text-[12px]">Mongoose</span>
              </span>

              <span className="w-10 pl-3 items-center flex flex-col justify-center  ">
                <img className="rounded-full h-10"  src={mongodb} alt="" />
                <span className="text-[12px]">MongoDB</span>
              </span>

              <span className="w-10 items-center flex flex-col justify-center  ">
                <img className="rounded-full h-10"  src={jwt} alt="" />
                <span className="text-[12px]">JWT</span>
              </span>

              <span className="w-10 items-center flex flex-col justify-center  ">
                <img className="rounded-full h-10"  src={apolloServer} alt="" />
                <span className="text-[12px] pl-1 ">Apollo server</span>
              </span>

              <span className="w-10 items-center flex flex-col justify-center  ">
                <img className="rounded-full h-10"  src={socketIo} alt="" />
                <span className="text-[12px] pl-1 ">Socket.io</span>
              </span>

            </div>
          </div>


          <div className="flex flex-col  w-[80%] pt-8">
            <h1 className="text-[#CCD6F6] text-[15px] border-b-2 max-w-max border-yellow-500 sm:text-[22px] font-bold">Databases and Services</h1>
            <div className="icons grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-4 py-3 ">
              <span className=" w-10 items-center flex flex-col justify-center  ">
                <img className="h-10"  src={git} alt="" />
                <span className="text-[12px]">Git</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center  ">
                <img className="h-10"  src={github} alt="" />
                <span className="text-[12px]">Github</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center  ">
                <img className="h-10"  src={docker} alt="" />
                <span className="text-[12px]">Docker</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center  ">
                <img className="h-10"  src={pg} alt="" />
                <span className="text-[12px]">PostgreSQL</span>
              </span>

              <span className=" w-10 items-center flex flex-col justify-center  ">
                <img className="h-10"  src={sqlite} alt="" />
                <span className="text-[12px]">SQLite3</span>
              </span>

              <span className=" w-10 items-center pl-3 flex flex-col justify-center  ">
                <img className="h-10"  src={mongodb} alt="" />
                <span className="text-[12px]">MongoDB</span>
              </span>

              <span className=" w-10 items-center pl-3 flex flex-col justify-center  ">
                <img className="h-8"  src={redis} alt="" />
                <span className="text-[12px]">Redis</span>
              </span>

              <span className=" w-10 items-center pl-3 flex flex-col justify-center  ">
                <img className="h-8"  src={amazonCloudFront} alt="" />
                <span className="text-[12px]">CloudFront</span>
              </span>

              <span className=" w-10 items-center pl-3 flex flex-col justify-center  ">
                <img className="h-8"  src={awsRoute} alt="" />
                <span className="text-[12px]">Route53</span>
              </span>

              <span className=" w-10 items-center pl-3 flex flex-col justify-center  ">
                <img className="h-8"  src={awsDynamoDB} alt="" />
                <span className="text-[12px]">DynamoDb</span>
              </span>

              <span className=" w-10 items-center pl-3 flex flex-col justify-center  ">
                <img className="h-8"  src={awsEbs} alt="" />
                <span className="text-[12px]">EBS</span>
              </span>

              <span className=" w-10 items-center pl-3 flex flex-col justify-center  ">
                <img className="h-8"  src={awsEc2} alt="" />
                <span className="text-[12px]">EC2</span>
              </span>

              <span className=" w-10 items-center pl-3 flex flex-col justify-center  ">
                <img className="h-8"  src={awsElasticBeanStalk} alt="" />
                <span className="text-[10px]">Elastic-Beanstalk</span>
              </span>

              <span className=" w-10 items-center pl-3 flex flex-col justify-center  ">
                <img className="h-8"  src={awsElasticCache} alt="" />
                <span className="text-[10px]">Elastic Cache</span>
              </span>

              <span className=" w-10 items-center pl-3 flex flex-col justify-center  ">
                <img className="h-8"  src={awsIam} alt="" />
                <span className="text-[10px]">AWS IAM</span>
              </span>

              <span className=" w-10 items-center pl-3 flex flex-col justify-center  ">
                <img className="h-8"  src={awsLambda} alt="" />
                <span className="text-[12px]">Lambda</span>
              </span>


              <span className=" w-10 items-center pl-3 flex flex-col justify-center  ">
                <img className="h-8"  src={awsRds} alt="" />
                <span className="text-[10px]">AWS RDS</span>
              </span>

              <span className=" w-10 items-center pl-3 flex flex-col justify-center  ">
                <img className="h-8"  src={awsS3} alt="" />
                <span className="text-[10px]">S3</span>
              </span>
            </div>
          </div>


        </div>
    </div>
  );
};

export default Qualifications;
