import React from "react";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./features/store"
import ReactDOM from 'react-dom/client';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);


let Icons = document.querySelectorAll(".navigation .icon");
Icons.forEach((icon) => {
  icon.addEventListener("click", () => {
    changeActive();
    icon.classList.add("active-nav");
  });
});


function changeActive() {
  Icons.forEach((icon) => {
    icon.classList.remove("active-nav");
  });
}
