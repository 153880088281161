import { useState } from "react";
import "./contact.css";
import { AiOutlineWhatsApp } from "react-icons/ai";
import {MdEmail} from "react-icons/md"
import {BsTelegram} from "react-icons/bs"

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function Contact() {

  const [status, setStatus] = useState("Submit")
  const [formData, setFormData] = useState({
    name:"",
    email:"",
    message:"",
  })


  const handleChange = (e) => {
    const {name, value} = e.target
    setFormData({ ...formData, [name]: value },);
  }

  const handleSubmit = async(e)=>{
    e.preventDefault();
    setStatus("Sending...");
    // const { name, email, message } = e.target.elements;
    // let details = {
    //   name: name.value,
    //   email: email.value,
    //   message: message.value,
    // };

    let response = await fetch("https://my-portfolio-server.herokuapp.com/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formData),
    });

    setStatus("Submit");
    let result = await response.json();

    toast.success(result.status, {
      position: toast.POSITION.TOP_CENTER
    });

    setFormData({
      name:"",
      email:"",
      message:"",
    })

  };
  
  return (
    <div id="contact" 
    className=" text-white mx-auto flex flex-col md:items-center justify-start 2xl:justify-center relative w-[100%] sm:w-[90%] md:w-[80%] py-2">

      <div className='flex flex-col items-start justify-start w-[100%]'>
        <p className="text-[#64ffda] text-[8px] sm:text-[10px] lg:text-[14px] xl:text-[16x]">
            Get in touch
        </p>
        <div className='flex items-center justify-start space-x-5 w-[100%]'>
            <p className='text-[#CCD6F6] font-bold text-[26px] sm:text-[35px]  '>
              Contact me via
            </p>
            <hr className="none sm:flex lg:w-[30%] h-[2px] text-black bg-slate-800 opacity-30" />
        </div>
      </div>

      <div className="mx-auto w-[80%] flex flex-col-reverse md:flex-row mt-8">
        <div className="contact-links pt-8 md:pt-0 flex flex-row md:flex-col space-x-2 md:space-x-0 md:space-y-5 md:ml-auto justify-center">
          <a
            href="mailto:sefaullahomar3@gmail.com"
            className="contact "
            target={"blank"}>
            <MdEmail className="contactIcon" />
          </a>

          <a
            href="https://wa.me/17789577596"
            className="contact whatsapp"
            target={"blank"}>
            <AiOutlineWhatsApp className="contactIcon" />
          </a>

          <a href="https://t.me/osefatul" className="contact">
            <BsTelegram className="contactIcon" />
          </a>
        </div>

        <form className= "w-[100%] md:w-[80%] mt-0 sm:mt:10 mx-auto flex flex-col items-center justify-center text-white space-y-5" onSubmit={handleSubmit}>
          <div className="w-full ">
            <label htmlFor="name">Name</label>
            <input 
            className="p-2 w-full text-black border-none !outline-none h-8 rounded-sm focus:outline-none" 
            value={formData.name} 
            onChange= {handleChange}
            type="text" 
            id="name"
            name="name"
            required 
            />
          </div>

          <div className="w-full">
            <label htmlFor="email">Email</label>
            <input className="w-full p-2 text-black h-8 border-none !outline-none rounded-sm" 
            type="email" 
            id="email"
            name="email"
            required 
            value={formData.email} 
            onChange= {handleChange}
            />
          </div>
          
          <div className="w-full">
            <label htmlFor="message">Message</label>
            <textarea className="w-full p-2 text-black border-none !outline-none  rounded-sm" 
            rows={10} 
            id="message"
            name="message"
            required 
            value={formData.message}
            onChange= {handleChange}
            />
          </div>
          
          <button 
          className="mx-auto flex items-center justify-center border-none !outline-none  border rounded-sm w-44 p-1 bg-green-800" type="submit">
            {status}
          </button>
          <ToastContainer />
        </form>
      </div>
    </div>
  );
}

export default Contact;
