import {
  dataFail,
  dataPending,
  projectsSuccess,
} from "../features/githubSlice";
import { Octokit } from "octokit";

// Create a personal access token at https://github.com/settings/tokens/new?scopes=repo
// const octokit = new Octokit({ auth: `ghp_WGuQmE33ZaT44CbobhasdasdakPe1JhD3U` });
const octokit = new Octokit({ auth: process.env.REACT_APP_AUTH_KEY });

const removedRepo = ["java_test_emc_class", "native-coffee", "html_emc_h5_v2"];

export const fetchGithubData = () => async (dispatch) => {
  dispatch(dataPending());

  try {
    const res = await octokit.request(
      `GET /user/repos?owner=${process.env.REACT_APP_USER}`,
      { per_page: 50 }
    );
    // console.log(res)

    const createGithubRepo = async () => {
      const newData = await Promise.all(
        res.data.map(async (repository) => {
          // Get repo readme.md
          if (!removedRepo.includes(repository.name)) {
            // console.log("repository.name", repository.name);
            const readmeContent = await octokit.request(
              `GET /repos/${process.env.REACT_APP_USER}/${repository.name}/readme?${repository.default_branch}`,
              {
                owner: process.env.REACT_APP_USER,
                repo: `${repository.name}`,
              }
            );

            // Get repo languages
            const repoLanguages = await octokit.request(
              `GET /repos/${process.env.REACT_APP_USER}/${repository.name}/languages`,
              {
                owner: process.env.REACT_APP_USER,
                repo: `${repository.name}`,
              }
            );

            const splitHtmlUrl = await repository.html_url.split("/"); //"https://github.com/osefatul/amazon-ecommerce-system"

            const splitDash = await splitHtmlUrl[splitHtmlUrl.length - 1]
              .split("-")
              .join(" "); //"amazon-ecommerce-system" => "amazon ecommerce system"

            return {
              title: splitDash,
              url: repository.html_url,
              languages: repoLanguages.data,
              readmeContent: window.atob(readmeContent.data.content),
            }; //decode encoded contents from base64 back to plain text
          }
        })
      );
      await dispatch(projectsSuccess([...newData]));
    };
    createGithubRepo();
  } catch (error) {
    console.log(error);
    dispatch(dataFail(error));
  }
};
