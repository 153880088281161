import React, { useRef, useState } from "react";
import "./feed.css";
import Intro from "../intro/Intro";
import img1 from "../props/mypic1.jpg"
import 'animate.css';


function Feed() {

  const [artPic, setArtPic] = useState(true)
  const el = useRef() 

  const smoothTransition = (state)=>{
    el.current.style.transition = "all 2s ease-in"
    if(!state){
      setArtPic(setArtPic(true))
    }
  }


  return (
    <div
      id="home"
      className=" text-white mx-auto md:ml-auto flex flex-col md:items-center justify-start xl:justify-center relative w-[100%] sm:w-[100%] 2xl:w-[100%] h-almost min-h-[400px]">
      <div className="relative border-animation w-[100%] sm:w-[90%] md:w-[100%] 2xl:w-[80%] boxx flex flex-col-reverse md:flex-row items-center justify-between md:shadow-md lg:shadow-lg 2xl:shadow-2xl mt-16 md:space-x-20 min-h-[350px]">
        <div className="w-[80%] md:w-[40%] md:pl-4 pb-2 sm:pb-1 my-2 md:my-0 md:mt-10">
          <Intro />
        </div>

        {/* Picture */}
        <div 
        className="w-[80%] md:w-full relative opacity-80 md:pr-4 py-3 md:mb-2 md:mt-4 "
        onMouseEnter={()=> smoothTransition(false)} 
        onMouseLeave ={()=> setArtPic(true)}
        ref={el}
        >
          {/* <img className="avatarPic opacity-30 pt-10 picShadow" src={img4} alt="" /> */}
          {/* <img className={` ${artPic?"opacity-50  ":"opacity-20 animate__fadeIn "} flex animate__animated top-10 sm:top-9 picShadow picHover`} src={artPic?img1:img2} alt="" /> */}
          <img className={` ${artPic?"opacity-30 ":"opacity-30 animate__fadeIn "} flex animate__animated top-20 sm:top-9 picShadow picHover`} src={img1} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Feed;
