/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGithubData } from '../../githubApi/githubApi'
import 'aos/dist/aos.css';
import { FiGithub, FiExternalLink } from "react-icons/fi";


function OtherProjects() {
    const dispatch = useDispatch()
    const {isLoading, projects} = useSelector(state => state.repos)
    let filteredProjects = Array.from(projects).filter(projects => projects !== undefined)
    
    // Some of the repos I don't want to include in my portfolio.
    const filteredRepos = [
        "awesome datascience", 
        "Datacamp Data Scientist with Python 2019 and 2020", 
        "DataScienceR",
        "html_emc_h5_v2",
        "native-coffee",
        "java_test_emc_class"
    ]

    const [allRepos, setAllRepos] = useState(filteredProjects.filter(({title}) => !filteredRepos.includes(title)))

    // const [selectedTab, setSelectedTab] = useState(false)
    // const tabs = [
    //     {id:0, title: "All"},
    //     {id:1, title: "MERN"},
    //     {id:2, title: "ReactJs", }, 
    //     {id:3, title: "Vanilla JS",},
    // ]

    // const handleSubmit =  (index, url) =>{
    //     setSelectedTab(index)
    // }

    useEffect(()=>{
        dispatch(fetchGithubData())
    },[])
        
    useEffect(()=>{
        setAllRepos(filteredProjects.filter(({title}) => !filteredRepos.includes(title)))
    },[projects])


    return (
    <div
    id="moreProjects" 
    className='pt-10 text-white mx-auto flex flex-col md:items-center justify-start 2xl:justify-center relative w-[100%] sm:w-[90%] md:w-[80%] h-100% pb-20'>
        
        <div className='flex flex-col items-start justify-start w-[100%]'>
            <p className="text-[#64ffda] text-[8px] sm:text-[10px] lg:text-[14px] xl:text-[16x]">
                My Projects
            </p>

            <div className='flex items-center justify-start space-x-5 w-[100%]'>
                <p className='text-[#CCD6F6] font-bold text-[26px] sm:text-[35px]'>
                    Other NoteWorthy Projects
                </p>
                <hr className="none sm:flex lg:w-[30%] h-[2px] text-black bg-slate-800 opacity-30" />
            </div>
        </div>

        {/* Selecting tab */}
        {/* <div className='pt-10 flex space-x-2 sm:space-x-4 items-center justify-center'>
            {
                tabs.map((tab, index) =>(
                    <div key={index}
                    // className='flex items-center justify-center border border-[#64ffda] hover:bg-[#06241d] hover:border-[#06241d] p-1 px-2 rounded-md  ease-in-out duration-300 '
                    className={`${index === selectedTab && "bg-[#06241d] border-slate-900 hoverText" } flex items-center justify-center border border-green-900 hover:bg-[#06241d] hover:border-[#06241d] p-1 px-2 rounded-md  ease-in-out duration-300 `}
                    onClick={() => handleSubmit(index, tab.url)}>
                        <h1 className={`${index === selectedTab && "hoverText text-[#eee344]"}  hoverText font-bold font-comorants text-[10px] sm:text-[16px]`}>
                        {tab.title}
                        </h1>
                    </div>
                ))
            }
        </div> */}

{/* All Projects map */}
        {
            isLoading ? 
            <div className='flex flex-wrap items-center justify-center w-[100%] pt-10' >
                Please wait, We are fetching data from Github...
            </div>:

            <div className='flex flex-wrap sm:items-center sm:justify-center w-[100%] pt-10 sm:pl-6 pl-0 '>
            {
                allRepos?.map((repo,index)=>(
                    <div
                        data-aos="fade-up"
                        data-aos-delay="50"
                        data-aos-duration="500"
                        data-aos-easing="ease-in-out"
                        // data-aos-mirror="true"
                        data-aos-once="false"
                        key={index}
                        className='bg-slate-900 flex flex-col items-start justify-between text-[12px] w-[90%] sm:w-[45%] lg:w-[30%] h-80 md:h-72 m-2 border border-slate-800 rounded-md p-4 pt-8 opacity-50'>


                            <h1 className='flex items-start justify-center text-[16px] font-bold capitalize text-[#CCD6F6]' >
                                {repo?.title}
                            </h1>

                            <p className='flex flex-wrap items-center justify-center'>
                                {
                                // console.log(repo.readmeContent.split("#").filter(str => str.includes("Intro"))[0]?.slice(9))
                                repo.readmeContent.split("#").filter(str => str.includes("Intro"))[0]?.slice(7, 180)
                                } ...
                            </p>

                            <div className={`flex items-center justify-start space-x-10 w-full`}>

                                <a href={repo.url} >
                                <FiGithub className='cursor-pointer  text-[15px] md:text-[18px] duration-300 transition ease-in-out delay-150' 
                                />
                                </a>

                                <a href={repo.url}>
                                    <FiExternalLink className='cursor-pointer text-[15px] md:text-[18px] duration-300 transition ease-in-out delay-150' 
                                    />
                                </a>
                            </div>
                        
                    </div>
                ))
            }
        </div>
        }
        
    </div>
)
}

export default OtherProjects